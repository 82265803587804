<template>
	<div class="suppierDetail">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" v-loading='loading'>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>基本信息</span>
        </div>
        <div class="content">

          <el-form-item label="活动名称："  prop="Name">
            <el-input v-model="ruleForm.Name" :disabled="currentEditStatus==2" style="width:400px;" clearable placeholder="最多输入20个字" maxlength="20"></el-input>
          </el-form-item>

          <el-form-item label="活动时间：" required>

            <el-form-item prop="StartTime" class="el-form-date-pick">
              <el-date-picker
                :disabled="currentEditStatus==2||currentEditStatus==1"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="ruleForm.StartTime"
                type="datetime"
                placeholder="开始时间"
                :picker-options='maxOptions'>
              </el-date-picker>
            </el-form-item>
            <span style="margin:0 5px;">~</span>
            <el-form-item prop="EndTime" class="el-form-date-pick">
              <el-date-picker
                :disabled="currentEditStatus==2"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="ruleForm.EndTime"
                type="datetime"
                placeholder="结束时间"
                :picker-options='minOptions'>
              </el-date-picker>
            </el-form-item>

          </el-form-item>

          <el-form-item label="返现区间：" required>
            <el-radio-group v-model="ruleForm.IsRandom" size="mini" @change="changeIntervalRadio" >
                <el-radio :label="false" :disabled="currentEditStatus==2||currentEditStatus==1"  class="radio-group">
                  固定区间
                  <el-form-item prop="FixedCashback" ref="FixedCashback" class="el-form-date-pick" >
                      <!-- :validate-event="ruleForm.IsRandom"  -->
                    <el-input  
                      style="margin: 0 10px;width:200px;"  
                      v-model="ruleForm.FixedCashback" 
                      :disabled="ruleForm.IsRandom||currentEditStatus==2||currentEditStatus==1" 
                      onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value>99|| value==0||value=='-'){value =''}"
                      onblur="this.v();if (value>99 || value==0||value=='-'){value =''}"
                    ></el-input>
                  </el-form-item>
                  %
                </el-radio>
                <el-radio :label="true" :disabled="currentEditStatus==2||currentEditStatus==1"  class="radio-group" style="margin-top:20px;">
                  <el-form-item prop="CashbackStart" ref="intervalValueStart" class="el-form-date-pick">
                    随机区间
                    <el-input  
                      :disabled="!ruleForm.IsRandom||currentEditStatus==2||currentEditStatus==1"   
                      style="margin:0 10px;width:200px;"
                      onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value>99|| value==0||value=='-'){value =''}"
                      onblur="this.v();if (value>99 || value==0||value=='-'){value =''}" 
                      clearable 
                      v-model="ruleForm.CashbackStart" ></el-input>
                  </el-form-item>
                  <el-form-item prop="CashbackEnd" ref="intervalValueEnd" class="el-form-date-pick">
                    %至
                    <el-input  
                      :disabled="!ruleForm.IsRandom||currentEditStatus==2||currentEditStatus==1" 
                      style="margin:0 10px;width:200px;" 
                      clearable 
                      onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value>99|| value==0||value=='-'){value =''}"
                      onblur="this.v();if (value>99 || value==0||value=='-'){value =''}" 
                      v-model="ruleForm.CashbackEnd" ></el-input>%
                  </el-form-item>
                </el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="活动商品："  prop="name">
            <span color="#909399">全部商品</span>
          </el-form-item>
        </div>
      </el-card>
		</el-form>
    <div class="footer">
      <div v-if="currentEditStatus!=2">
        <el-button style="width:240px;" @click="suppilerCansel">取消</el-button>
        <el-button style="width:240px;" type="primary" @click="saveSupiler('ruleForm')" :loading="loading">保存</el-button>
      </div>
      <div v-else>
        <el-button style="width:240px;" @click="suppilerBack">返回</el-button>
      </div>
    </div>
	</div>
</template>

<script>
import apiList from '@/api/other.js'
  
	export default {
    
		data() {
      var checkName = (rule, value, callback) =>{
				if(!value){
					return callback(new Error('请输入活动名称'));
				}else if(!value.trim() ){
					return callback(new Error('请输入活动名称'));
				}else{
					return callback()
				}
			};
      var checkIntervalValue = (rule, value, callback) => {
        if(!this.ruleForm.IsRandom&&(!value)){
          return callback(new Error('请输入固定区间'));
        }else{
          return callback();
        }
      };
      var checkIntervalValueStart = (rule, value, callback) => {
        if(this.ruleForm.IsRandom&&(!value)){
          return callback(new Error('请输入随机区间'));
        }else{
          if(this.ruleForm.CashbackEnd!=''&&(Number(value)>Number(this.ruleForm.CashbackEnd)||Number(value)==Number(this.ruleForm.CashbackEnd))){
            return callback(new Error('最大区间需大于最小区间'));
          }else{
            return callback();
          }
        }
      };
      var checkIntervalValueEnd = (rule, value, callback) => {
        if(this.ruleForm.IsRandom&&(!value)){
          return callback(new Error('请输入随机区间'));
        }else{
          if(this.ruleForm.CashbackStart!=''&&(Number(value)<Number(this.ruleForm.CashbackStart)||Number(value)==Number(this.ruleForm.CashbackStart))){
            return callback(new Error('最大区间需大于最小区间'));
          }else{
            return callback();
          }
        }
      };
			return {
        loading: false,
        currentQueryId:'',
				ruleForm: {
          Name: '',
          StartTime:'',
          EndTime :'',
          IsRandom:false,
          CashbackStart:'',
          CashbackEnd:'',
          FixedCashback:'',
        },
        currentCount:'',
				rules: {
          Name: [{
            required: true, 
            // message: '请输入活动名称',
            trigger: 'blur',
            validator:checkName
          }],
          StartTime: [{
            required: true, 
            message: '请选择开始日期',
            trigger: 'change'
          }],
					EndTime: [{ 
            required: true, 
            message: '请选择结束日期',
            trigger: 'change'
          }],
          FixedCashback:[
            {
              trigger: 'blur',
              validator:checkIntervalValue
            }
          ],
          CashbackStart:[
            {
              trigger: 'blur',
              validator:checkIntervalValueStart
            }
          ],
          CashbackEnd:[
            {
              trigger: 'blur',
              validator:checkIntervalValueEnd
            }
          ],
        },
        currentEditId:'',
        currentEditCopyId:'',
        currentEditStatus:null
      }
		},
		created() {
      this.currentEditId=this.$route.query.id
      // currentEditStatus:2=查看，不可编辑所有
      this.currentEditStatus=this.$route.query.status
      this.currentEditCopyId=this.$route.query.copyId
      if(this.currentEditId||this.currentEditCopyId){
        this.getData()
      }		
    },
    watch:{
      "ruleForm.CashbackStart": function toggle(){
        this.$refs['ruleForm'].validateField('CashbackEnd')
      },
      "ruleForm.CashbackEnd": function toggle(){
        this.$refs['ruleForm'].validateField('CashbackStart')
      }
    },
    computed: {
      //日期选择器限制选择
      minOptions: function () {
        let limitTime = this.ruleForm.StartTime
        let d = new Date();
        let day=d.getDate();
        let month=d.getMonth()+1;
        let year=d.getFullYear();
        let currentDate =year+ '-'+month+ '-'+day+' '+ '00:00:00'
        return {
          disabledDate (time) {
            if (limitTime) {
              return time < new Date(new Date(limitTime).toLocaleDateString())
            } else{
              return time < new Date(currentDate)
            }
          },
        }
      },
      //日期选择器限制选择
      maxOptions: function () {
        let limitTime =this.ruleForm.EndTime
        let d = new Date();
        let day=d.getDate();
        let month=d.getMonth()+1;
        let year=d.getFullYear();
        let currentDate =year+ '-'+month+ '-'+ day +' '+ '00:00:00'
        return {
          disabledDate (time) {
            if (limitTime) {
              return ( 
                time >new Date(new Date(limitTime).toLocaleDateString()) ||
                time < new Date(currentDate)
              )
            } else{
              return time < new Date(currentDate)
            }
          }
        }
      }
    },
		methods: {
      async getData() {
        this.loading = true;
        let id =this.currentEditId==undefined?this.currentEditCopyId:this.currentEditId
        try {
          let data = {
            Id :id
          }
          let result = await apiList.activitycashbackEditInit(data);
          this.ruleForm= {
            Name: result.Result.Name,
            StartTime:result.Result.StartTime,
            EndTime :result.Result.EndTime,
            EndTime:result.Result.EndTime,
            IsRandom :result.Result.IsRandom ,
            CashbackStart:result.Result.IsRandom?result.Result.CashbackStart:'',
            CashbackEnd:result.Result.IsRandom?result.Result.CashbackEnd:'',
            FixedCashback:result.Result.IsRandom?'':result.Result.CashbackStart,
          }
          if(this.currentEditCopyId){
            this.ruleForm.StartTime=''
            this.ruleForm.EndTime=''
          }
          this.$nextTick(()=>{
            this.$refs['ruleForm'].clearValidate()
          })
          // this.initForm()
        } catch (error) {
          console.log(error);
          this.loading = false
        } finally {
          this.loading = false;
        }
			},
      changeIntervalRadio(e){
        if(e==1){
          this.ruleForm.FixedCashback=''
          this.$refs['FixedCashback'].clearValidate()
        }else{
          this.ruleForm.CashbackStart=''
          this.ruleForm.CashbackEnd=''
          this.$refs['intervalValueStart'].clearValidate()
          this.$refs['intervalValueEnd'].clearValidate()
        }
      },
    // 取消
			suppilerCansel() {
        this.$confirm('您还未保存编辑的数据，离开页面后，系统将不会为您保存数据，是否确认继续离开？', '提示', {
					confirmButtonText: '确认离开',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(() => {
					this.$router.push({
            path: '/market/orderCashBack/index'
          });
				})
      },
      suppilerBack(){
        this.$router.push({
          path: '/market/orderCashBack/index'
        });
      },
    	// 保存，处理数据
      async  saveSupiler(ruleForm) {
        this.$refs[ruleForm].validate(async (valid) => {
          if (valid) {
            this.loading = true
            try{
              let data = {
                Id 	: this.currentEditId,
                Name 	: this.ruleForm.Name,
                StartTime	: this.ruleForm.StartTime,
                EndTime	: this.ruleForm.EndTime,
                IsRandom :this.ruleForm.IsRandom,
                CashbackStart : this.ruleForm.IsRandom?this.ruleForm.CashbackStart:this.ruleForm.FixedCashback,
                CashbackEnd: this.ruleForm.IsRandom?this.ruleForm.CashbackEnd:this.ruleForm.FixedCashback,
              }
              let result = await apiList.activitycashbackEditsave(data);
              // if(!result.Result){
              this.$router.push({
                path: '/market/orderCashBack/index'
              });
              this.$message({showClose: true,
                type: 'success',
                message: '操作成功'
              });
              this.loading = false
            } catch (error) {
              console.log(error);
              this.$message({showClose: true,
                  type: 'error',
                  message: '操作失败'
                });
              this.loading = false
            } finally {
              this.loading = false
            }
          }
        })
      },
		}
	}
</script>

<style lang="less" scoped>
	.suppierDetail {
    background-color: #fff;
    // margin: 30px;
    .remarks{
      color: #bbb;
      font-size: 14px;
    }
		.parent-box {
			position: relative;

			.close-bosx {
				position: absolute;
				right: -5px;
				top: -13px;
				font-size: 18px;
			}
    }
   
		.markRed {
			position: absolute;
			left: -94px;
			top: 5px;
			color: red;
			font-size: 18px;
    }
  }
  .dialog{
		width: 100%;
		/deep/.el-dialog__header {
				border-bottom: 1px solid #ddd;
    }
  }
  .table-container{
    position: relative;
    overflow: hidden;
  }
  .dialog-name-content{
    display: flex;
    flex-direction: row;
    img{
      width:60px;
      height:60px;
      object-fit:cover;
      margin-right: 10px;
    }
    .name{
      width: 200px;
      overflow:hidden;
      text-overflow:ellipsis;
      display:-webkit-box;
      -webkit-line-clamp:2;
      -webkit-box-orient:vertical;
    }
    span{
      color: #E51C23;
      font-size:12px;
    }
  }
  .el-form-date-pick{
    display: inline-block;
  }
  .radio-group{
    display: block;
  }
  .footer{
    text-align:center;
    line-height: 80px;
    background-color: #fff;
    position: fixed;
    bottom: 0px;
    width: 100%;
    box-shadow: 13px 1px 6px #999;
  }
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .number__input {
    border: 1px solid #ccc;
  }
  
</style>
